.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  /* cursor: pointer; */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* .layout-topnav .ant-menu.ant-menu-dark .ant-menu-item-selected, .layout-topnav .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected { */
.layout-topnav .ant-menu .ant-menu-item-selected, .layout-topnav .ant-menu-submenu-popup .ant-menu-item-selected {
  background-color: unset;
  color: unset;
}

.layout-topnav .ant-menu.ant-menu-dark .ant-menu-item-selected, .layout-topnav .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    border-bottom: 2px solid #1765ad;
}


.prompt-stream h4.ant-list-item-meta-title {
  margin-top: 0;
}

.store-cart-list h4.ant-list-item-meta-title {
  margin-top: 0;
}

.account-feature-flag-list h4.ant-list-item-meta-title {
  margin-top: 0;
}

.account-prompt-pack-list h4.ant-list-item-meta-title {
  margin-top: 0;
}

.account-credit-transaction-list h4.ant-list-item-meta-title {
  margin-top: 0;
}

.workflow-execution-list h4.ant-list-item-meta-title {
  margin-top: 0;
}

.named-workflow-list h4.ant-list-item-meta-title {
  margin-top: 0;
}

.hover-copy-content {
  position: relative;
}
.hover-copy-content .hover-copy-button-wrapper {
  /* display: none; */
  position: absolute;
  top: 0;
  right: 0;
  display: block;
}

/* .hover-copy-content:hover .hover-copy-button-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
} */

.prompt-template-replaced {
  font-weight: bolder
}

.App-content {
  /* background-color: #001529; */
  /* background-color: #282c34; */
  /* background-color: #00152D; */
  /* background-color: #1d1d1d; */
  /* background-color: #1f1f1f; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  /* font-size: calc(10px + 2vmin); */
  /* color: white; */
}

.base-theme-light .App-content {
  /* background-color: #001529; */
  /* background-color: #282c34; */
  /* background-color: #00152D; */
  /* background-color: #1d1d1d; */
  /* background-color: #1f1f1f; */
  background-color: #fdfdfd;
}

/* .signal-stream .ant-list-item {
  cursor: pointer
} */




.layout-sidebar .App-content {
  padding: 0px 16px;
}

.layout-topnav .App-content {
  padding: 0px 8px 50px;
}

.layout-topnav .ant-layout-header {
  /* padding-inline: 10px; */
  padding-inline: 0px;
}

.layout-topnav .ant-layout-header .app-header {
  margin-right: 10px;
}

.ant-layout-footer .footer-wrapper {
  margin-right: 10px;
  margin-left: 10px;
}

/* @media only screen and (min-width: 768px) {
  .layout-topnav .App-content {
    padding: 0px 20px 50px;
  }

  .layout-topnav .ant-layout-header .app-header {
    margin-right: 20px;
    margin-left: 20px;
  }

  .ant-layout-footer .footer-wrapper {
    margin-right: 20px;
    margin-left: 20px;
  }
} */

@media only screen and (min-width: 1100px) {
  .layout-topnav .App-content {
    padding: 0px 20px 50px;
  }

  .layout-topnav .ant-layout-header .app-header {
    margin-right: 20px;
    margin-left: 20px;
  }

  .ant-layout-footer .footer-wrapper {
    margin-right: 20px;
    margin-left: 20px;
  }
}


@media only screen and (min-width: 1390px) {
  .layout-topnav .App-content {
    /* padding: 0px 50px 50px; */
    padding: 0px 0px 50px;
    width: 1350px;
    max-width: 1350px;
    margin: auto;
  }

  .layout-topnav .App-content.app-content-wide {
    /* padding: 0px 50px 50px; */
    padding: 0px 20px 50px;
    width: 100%;
    max-width: 100%;
    margin: auto;
  }

  .layout-topnav .ant-layout-header {
    /* padding-inline: 50px; */

  }

  .layout-topnav .ant-layout-header .app-header {
    width: 1350px;
    max-width: 1350px;
    margin: auto;
  }

  .ant-layout-footer .footer-wrapper {
    width: 1350px;
    max-width: 1350px;
    /* margin-left: unset;
    margin-right: unset; */
    margin: auto;
  }
}


.layout-topnav .App-content {
  /* padding: 0px 50px 50px; */
  min-height: 75vh;
}

.layout-topnav.base-theme-light {
  /* background: #f0f0f0; */
  /* color: #0f0f0f; */
}

.layout-topnav .App-page-content {
  min-height: 60vh;
}

.layout-topnav .ant-layout-header {
  /* line-height: 3.8em; */
}

.layout-topnav .ant-layout-header .ant-menu-item{
  /* line-height: 3.8em; */
  height: 64px;
}

.footer-section {
  margin-bottom: 36px;
}

.ant-btn {
  box-shadow: none;
}

.workflow-preview.ant-timeline .ant-timeline-item-last > .ant-timeline-item-content {
  min-height: unset;
}

a.unstyled {
  color: unset
}

a.unstyled:hover {
  text-decoration: underline;
  color: unset;
}



/*--- base-theme-dark --- */

.base-theme-dark .signal-stream a {
  color: rgba(255, 255, 255, 0.85);
}

.base-theme-dark .signal-stream .ant-list-item a:hover {
  /* color: #eee; */
  text-decoration: underline;
}

.base-theme-dark .prompt-stream .ant-list-item .ant-list-item-meta-description {
  /* color: #eee; */
  color: #e5e5e5;
  /* text-decoration: underline; */
}


.base-theme-dark .footer-section a:hover {
  color: #e5e5e5;
}

.base-theme-dark.layout-topnav .ant-layout-header {
  /* background-color: #000; */
  /* color: white; */
}


/* .base-theme-dark.layout-topnav .ant-layout-header .ant-menu-light {
  background-color: #000;
  color: white;
}

.base-theme-dark.layout-topnav .ant-layout-header .ant-menu-dark {
  background-color: #000;
  color: white;
} */

.base-theme-dark.layout-topnav .ant-layout-header .ant-menu-dark, .base-theme-dark.layout-topnav .ant-layout-header .ant-menu-light {
  /* background-color: #000; */
  background-color: unset;
  color: white;
}



.base-theme-dark .footer-section a {
  color: #b0b0b0;
}

.base-theme-dark .App-page-content li {
  color: rgba(255, 255, 255, 0.85);
}

.base-theme-dark .App-page-content pre {
  color: rgba(255, 255, 255, 0.85);
}

.base-theme-dark .prompt-stream a {
  color: #e5e5e5;
}
.base-theme-dark .prompt-stream a:hover {
  color: #ffffff;
}

.base-theme-dark.layout-topnav {

  /* background-color: #4c0f0b; */
  /* background-color: unset; */
  color: #e5e5e5;
}

.base-theme-dark .prompt-template-replaced {
  color: #fff;
  background-color: #111;
  padding: 2px 5px;
}


.prompt-stream .ant-list-item .ant-list-item-action a {
  color: unset;
}


/*--- base-theme-light --- */


.base-theme-light.layout-topnav .ant-layout-header .ant-menu-light {
  color: #020202;
}

.base-theme-light.layout-topnav .ant-layout-header .ant-menu-dark {
  color: #020202;
}

.base-theme-light .footer-section a {
  color: #5b5b5b;
}

.base-theme-light .footer-section a:hover {
  color: #2d2d2d;
}

.base-theme-light .prompt-stream .ant-list-item .ant-list-item-meta-description {
  /* color: #eee; */
  color: #000;
  /* text-decoration: underline; */
}

.base-theme-light .prompt-stream a {
  color: #151515;
}
.base-theme-light .prompt-stream a:hover {
  color: #000;
}

.base-theme-light .prompt-template-replaced {
  color: #000;
  background-color: #EEE;
  padding: 2px 5px;
}

/* --- Dark --- */

.theme-dark.layout-topnav .ant-layout-header,
.theme-dark.layout-topnav .ant-layout-header .ant-menu-dark,
.theme-dark.layout-topnav .ant-layout-header .ant-menu-light,
.ant-menu-submenu .ant-menu-submenu-popup .ant-menu-dark .ant-menu,
.ant-menu-dark.ant-menu-submenu > .ant-menu,
.theme-dark .ant-drawer .ant-drawer-content.drawer-nav, .theme-dark .ant-menu-submenu, .theme-dark .ant-menu-submenu .ant-menu.ant-menu-sub,
.theme-dark .ant-layout-footer{
  background-color: #000;
  /* color: white; */
}

.theme-dark .prompt-template-replaced {
  color: #fff;
  background-color: #333;
  padding: 2px 5px;
}


.base-theme-light.layout-topnav {

  /* background-color: #4c0f0b; */
  /* background-color: unset; */
  color: #020202;
}

/*--- Light --- */

.theme-light.layout-topnav .ant-layout-header {
  background-color: #f5f5f5;
}

.theme-light.layout-topnav .ant-layout-header .ant-menu-light {
  background-color: #f5f5f5;
}

.theme-light.layout-topnav .ant-layout-header .ant-menu-dark {
  background-color: #f5f5f5;
}




/* --- Pro --- */
.theme-pro.layout-topnav .ant-layout-header, .theme-pro.layout-topnav .ant-layout-header .ant-menu-light, .theme-pro.layout-topnav .ant-layout-header .ant-menu-dark {
  background-color: #002245;
  color: white;
}

.theme-pro.layout-topnav .ant-layout-header .ant-space-horizontal .ant-btn-text {
  color: white;
}

.theme-pro.drawer-nav.ant-drawer-content {
  /* background: #002245; */
  color: #222;

}

.theme-pro.layout-topnav {
  background-color: #fff;
  background: #fff;
}

/* --- Purple --- */
.theme-purple.layout-topnav .ant-layout-header, .theme-purple.layout-topnav .ant-layout-header .ant-menu-light, .theme-purple.layout-topnav .ant-layout-header .ant-menu-dark {
  background-color: #3a2673;
  color: white;
}

.theme-purple.layout-topnav .ant-layout-header .ant-space-horizontal .ant-btn-text {
  color: white;
}

.theme-purple .ant-layout-footer {
  background-color: #f0eef9;
}


/* --- Green --- */
.theme-green.layout-topnav .ant-layout-header, .theme-green.layout-topnav .ant-layout-header .ant-menu-light, .theme-green.layout-topnav .ant-layout-header .ant-menu-dark {
  background-color: #0e390e;
  color: white;
}

.theme-green.layout-topnav .ant-layout-header .ant-space-horizontal .ant-btn-text {
  color: white;
}

.theme-green.layout-topnav .ant-menu.ant-menu-dark .ant-menu-item-selected, .theme-green.layout-topnav .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  border-bottom: 2px solid #1d761d;
}

.theme-green .ant-layout-footer {
  background-color: #e8f1e8;
}

/* --- catppuccin-mocha --- */
.theme-catppuccin-mocha.ant-layout {
  /* background-color: #313244; */
  /* color: #cdd6f4; */
}

.theme-catppuccin-mocha.layout-topnav .ant-layout-header, .theme-catppuccin-mocha.layout-topnav .ant-layout-header .ant-menu-light, .theme-catppuccin-mocha.layout-topnav .ant-layout-header .ant-menu-dark {
  /* background-color: #11111b; */
  /* background-color: unset; */
  /* color: #cdd6f4; */
}

.theme-catppuccin-mocha.layout-topnav .ant-layout-header .ant-space-horizontal .ant-btn-text {
  /* color: #cdd6f4; */
}

.theme-catppuccin-mocha.layout-topnav .ant-menu.ant-menu-dark .ant-menu-item-selected, .theme-catppuccin-mocha.layout-topnav .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  border-bottom: 2px solid #89b4fa;
}

.theme-catppuccin-mocha .ant-layout-footer {
  background-color: #181825;
  /* background-color: unset; */
}

.theme-catppuccin-mocha.layout-topnav .ant-layout-header,
.theme-catppuccin-mocha.layout-topnav .ant-layout-header .ant-menu-dark,
.theme-catppuccin-mocha.layout-topnav .ant-layout-header .ant-menu-light,
.theme-catppuccin-mocha .ant-drawer .ant-drawer-content.drawer-nav, .theme-catppuccin-mocha .ant-menu-submenu, .theme-catppuccin-mocha .ant-menu-submenu .ant-menu.ant-menu-sub {
  /* background-color: #000; */
  /* background-color: unset; */
  /* color: white; */
}


/* --- pyro-light --- */


.theme-pyro-light.layout-topnav .ant-layout-header .ant-space-horizontal .ant-btn-text {
  color: #DEDEDE;
}


.theme-pyro-light.layout-topnav .ant-menu.ant-menu-dark .ant-menu-item-selected,
.theme-pyro-light.layout-topnav .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
.theme-pyro-light.layout-topnav .ant-menu.ant-menu-light .ant-menu-item-selected,
.theme-pyro-light.layout-topnav .ant-menu-submenu-popup.ant-menu-light .ant-menu-item-selected {
  border-bottom: 2px solid #49556a;
}

.theme-pyro-light .ant-layout-footer {
  /* background-color: #181825; */
  background-color: #f2f2f2;
  padding: 24px 0;
  
  /* background-color: unset; */
}

.theme-pyro-light.layout-topnav .ant-layout-header {
  background-color: #2a313d;
  background: linear-gradient(180deg, rgba(92,42,125,0.20) 0%, rgba(92,42,125,0.20) 2px, #2a313d 5px, #2a313d 100%), 
  radial-gradient(circle, rgba(92,42,125,1) 0%, rgba(136,19,19,1) 50%, rgba(172,121,49,1) 100%);
}

.theme-pyro-light.layout-topnav .ant-layout-header{
  color: #2b2b2b;
  background-color: unset;
}

.theme-pyro-light.layout-topnav {
  background-color: #e5e5e5;
  background: #e5e5e5;
  background: linear-gradient(180deg, rgba(92,42,125,0.20) 0%, rgba(92,42,125,0.20) 2px, #fff 5px, #fff 100%), 
  linear-gradient(90deg, rgba(172,121,49,1) 0%, rgba(136,19,19,1) 35%, rgba(92,42,125,1) 50%, rgba(136,19,19,1) 65%, rgba(172,121,49,1) 100%);
}

.theme-pyro-light.layout-topnav .ant-layout-header .ant-menu-dark,
.theme-pyro-light.layout-topnav .ant-layout-header .ant-menu-light  {
  background: unset;
  color: #e5e5e5;

}
.theme-pyro-light .ant-drawer .ant-drawer-content.drawer-nav, .theme-pyro-light .ant-menu-submenu, .theme-pyro-light .ant-menu-submenu .ant-menu.ant-menu-sub {
  background-color: #2a313d;
  color: #e5e5e5;

}

/* --- pyro-dark --- */

.theme-pyro-dark.layout-topnav .ant-layout-header,
.theme-pyro-dark.layout-topnav .ant-layout-header .ant-menu-dark,
.theme-pyro-dark.layout-topnav .ant-layout-header .ant-menu-ligh,
.ant-menu-dark.ant-menu-submenu > .ant-menu,
.ant-menu-dark,
.theme-pyro-dark .ant-drawer .ant-drawer-content.drawer-nav, .theme-pyro-dark .ant-menu-submenu, .theme-pyro-dark .ant-menu-submenu .ant-menu.ant-menu-sub {
  background-color: #222831;
}

.theme-pyro-dark.layout-topnav .ant-layout-header .ant-space-horizontal .ant-btn-text {
  color: #DEDEDE;
}


.theme-pyro-dark.layout-topnav .ant-menu.ant-menu-dark .ant-menu-item-selected,
.theme-pyro-dark.layout-topnav .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
.theme-pyro-dark.layout-topnav .ant-menu.ant-menu-light .ant-menu-item-selected,
.theme-pyro-dark.layout-topnav .ant-menu-submenu-popup.ant-menu-light .ant-menu-item-selected {
  border-bottom: 2px solid #49556a;
}

.theme-pyro-dark .ant-layout-footer {
  background-color: #222831;
  padding: 24px 0;
}

.theme-pyro-dark.layout-topnav .ant-layout-header{
  background-color: #222831;
  background: #222831;
  background: linear-gradient(180deg, rgba(92,42,125,0.20) 0%, rgba(92,42,125,0.20) 2px, #232831 5px, #232831 100%), 
  linear-gradient(90deg, rgba(172,121,49,1) 0%, rgba(136,19,19,1) 35%, rgba(92,42,125,1) 50%, rgba(136,19,19,1) 65%, rgba(172,121,49,1) 100%);
  color: #e5e5e5;
  background-color: unset;
}



.theme-pyro-dark.layout-topnav .ant-layout-header .ant-menu-dark,
.theme-pyro-dark.layout-topnav .ant-layout-header .ant-menu-light  {
  background: unset;
  color: #e5e5e5;

}
.theme-pyro-dark .ant-drawer .ant-drawer-content.drawer-nav, .theme-pyro-dark .ant-menu-submenu, .theme-pyro-dark .ant-menu-submenu .ant-menu.ant-menu-sub {
  background-color: #2a313d;
  color: #e5e5e5;

}

/* --- catppuccin-latte --- */

.theme-catppuccin-latte.layout-topnav {
  background-color: #fff;
  background: #fff;
}

.theme-catppuccin-latte.layout-topnav .ant-layout-header .ant-btn-text, .theme-catppuccin-latte.layout-topnav .ant-layout-header, .theme-catppuccin-latte.layout-topnav .ant-layout-header .ant-menu-light, .theme-catppuccin-latte.layout-topnav .ant-layout-header .ant-menu-dark {
  background-color: #001529;

  color: white;
}

.theme-catppuccin-latte.layout-topnav .ant-menu.ant-menu-dark .ant-menu-item-selected, .theme-catppuccin-latte.layout-topnav .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  border-bottom: 2px solid #89b4fa;
}

/* --- alt-light --- */

.theme-alt-light.layout-topnav .ant-layout-header .ant-btn-text, .theme-alt-light.layout-topnav .ant-layout-header, .theme-alt-light.layout-topnav .ant-layout-header .ant-menu-light, .theme-alt-light.layout-topnav .ant-layout-header .ant-menu-dark {
  /* background-color: #0e390e; */
  color: white;
}

.theme-alt-light.layout-topnav .ant-menu.ant-menu-dark .ant-menu-item-selected, .theme-alt-light.layout-topnav .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  border-bottom: 2px solid #4285f4;
}

/* --- alt-dark --- */

.theme-alt-dark.layout-topnav .ant-layout-header,
.theme-alt-dark.layout-topnav .ant-layout-header .ant-menu-dark,
.theme-alt-dark.layout-topnav .ant-layout-header .ant-menu-ligh,
.ant-menu-dark.ant-menu-submenu > .ant-menu,
.ant-menu-dark,
.theme-alt-dark .ant-drawer .ant-drawer-content.drawer-nav, .theme-alt-dark .ant-menu-submenu, .theme-alt-dark .ant-menu-submenu .ant-menu.ant-menu-sub {
  background-color: unset;
  }


.theme-alt-dark.layout-topnav .ant-menu.ant-menu-dark .ant-menu-item-selected, .theme-alt-dark.layout-topnav .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  border-bottom: 2px solid #1A73E8;
}

.theme-alt-dark .ant-layout-footer {
  background-color: #161718;
}

.theme-alt-dark .prompt-template-replaced {
  color: #fff;
  background-color: #333;
  padding: 2px 5px;
}


/* --- dracula --- */

.theme-dracula.layout-topnav .ant-layout-header,
.theme-dracula.layout-topnav .ant-layout-header .ant-menu-dark,
.theme-dracula.layout-topnav .ant-layout-header .ant-menu-ligh,
.ant-menu-dark.ant-menu-submenu > .ant-menu,
.ant-menu-dark,
.theme-dracula .ant-drawer .ant-drawer-content.drawer-nav, .theme-dracula .ant-menu-submenu, .theme-dracula .ant-menu-submenu .ant-menu.ant-menu-sub {
  /* background-color: #000; */
  background-color: unset;
  
  /* color: white; */
}

.theme-dracula.ant-layout {
  /* background-color: #282a36; */
  /* color: #cdd6f4; */
}

.theme-dracula.layout-topnav .ant-layout-header, .theme-dracula.layout-topnav .ant-layout-header .ant-menu-light, .theme-dracula.layout-topnav .ant-layout-header .ant-menu-dark {
  /* background-color: #11111b; */
  /* background-color: unset; */
  /* color: #cdd6f4; */
}

.theme-dracula.layout-topnav .ant-layout-header .ant-space-horizontal .ant-btn-text {
  /* color: #cdd6f4; */
}

.theme-dracula.layout-topnav .ant-menu.ant-menu-dark .ant-menu-item-selected, .theme-dracula.layout-topnav .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  border-bottom: 2px solid #8be9fd;
}

.theme-dracula .ant-layout-footer {
  background-color: #181825;
  /* background-color: unset; */
}

/*--- nord-light --- */

.theme-nord-light.layout-topnav {
  background-color: #fff;
  background: #fff;
}

.theme-nord-light.layout-topnav .ant-layout-header, .theme-nord-light.layout-topnav .ant-layout-header .ant-menu-light, .theme-nord-light.layout-topnav .ant-layout-header .ant-menu-dark {
  background-color: #eceff4;
}

.theme-nord-light.layout-topnav .ant-layout-header,
.theme-nord-light.layout-topnav .ant-layout-header .ant-menu-dark,
.theme-nord-light.layout-topnav .ant-layout-header .ant-menu-light,
.ant-menu-dark.ant-menu-submenu > .ant-menu,
.ant-menu-dark,
.theme-nord-light .ant-drawer .ant-drawer-content.drawer-nav, .theme-nord-light .ant-menu-submenu, .theme-nord-light .ant-menu-submenu .ant-menu.ant-menu-sub {
  background-color: #eceff4;
}


/* -- Nord Dark --- */
.theme-nord-dark.layout-topnav .ant-layout-header,
.theme-nord-dark.layout-topnav .ant-layout-header .ant-menu-dark,
.theme-nord-dark.layout-topnav .ant-layout-header .ant-menu-ligh,
.ant-menu-dark.ant-menu-submenu > .ant-menu,
.ant-menu-dark,
.theme-nord-dark .ant-drawer .ant-drawer-content.drawer-nav, .theme-nord-dark .ant-menu-submenu, .theme-nord-dark .ant-menu-submenu .ant-menu.ant-menu-sub {
  background-color: #232831;
}


.theme-nord-dark.layout-topnav .ant-menu.ant-menu-dark .ant-menu-item-selected, .theme-nord-dark.layout-topnav .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  border-bottom: 2px solid #8fbcbb;
}

.theme-nord-dark .ant-layout-footer {
  background-color: #232831;
}


/* --- */



.layout-topnav .ant-layout-header .ant-menu-horizontal {
  border: 0;
}



/* .ant-drawer .ant-drawer-content.drawer-nav .ant-menu-dark {
  background: none;
} */

.ant-drawer .ant-drawer-content.drawer-nav .ant-drawer-header {
  padding-top: 0px;
  padding-bottom: 0px;
}
.ant-drawer .ant-drawer-content.drawer-nav .ant-menu {
  background: none;
}
.App-page-content {
  /* background-color: #001529; */
  /* background-color: #282c34; */
  /* background-color: #00152D; */
  /* background-color: #1d1d1d; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  /* font-size: calc(10px + 1vmin); */
  /* padding: 0px 50px 12px; */
  /* color: white; */
  /* padding: 4px 16px; */
}

.App-Page-content pre {
  white-space: pre-wrap;       /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
}



.App-link {
  /* color: #6d5dc9;
  cursor: pointer; */
}

.App-page-header-right {
  margin: 8px 0;
}

@media only screen and (min-width: 768px) {
  .App-page-header-right {
    float: right;
    margin: 0;
    /* display: inline */
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
